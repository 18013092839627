import React, {FunctionComponent, useEffect, useState} from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import {OurModalProps} from "./OurModalTypes";
import "./OurModal.scss";

const OurModal: FunctionComponent<OurModalProps> = (commissionModalProps) => {
  const {children, disableClose, parentToggle, setParentClose} = commissionModalProps;
  const [isOpen, setIsOpen] = useState<boolean>(parentToggle);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    setIsOpen(parentToggle);
  }, [parentToggle])

  function handleClose() {
    if (!disableClose) {
      setIsOpen(false);
      setParentClose(false);
    }
  }

  return (
    <Modal
      className="our-modal"
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {backgroundColor: "rgba(0, 0, 0, 0.65)"},
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className="popup-base">
          {children}
        </div>
      </Fade>
    </Modal>
  )
}

export default OurModal;