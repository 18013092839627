import React, {CSSProperties, FunctionComponent, useEffect, useState} from "react";
import OurModal from "../OurModal/OurModal";
import {OurModalProps} from "../OurModal/OurModalTypes";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import "../../styles/Modal.scss";
import "./OurPaperModal.scss";
import IconButton from "@material-ui/core/IconButton";
import {CloseTooltip} from "../../styles/MaterialUIStyling";
import OurToastContainer from "../OurToastContainer/OurToastContainer";

export type PaperModalProps = OurModalProps & {
  overrideModalStyle?: CSSProperties,
  overrideCloseIconStyle?: CSSProperties,
  overrideLeftSideElementStyle?: CSSProperties,
  overrideNavBarStyle?: CSSProperties,
  disableClose?: boolean,
  leftSideElement?: JSX.Element,
  toastContainerId?: string,
  scrollable?: boolean,
}

const OurPaperModal: FunctionComponent<PaperModalProps> = (props) => {
  const {
    children,
    disableClose,
    leftSideElement,
    overrideCloseIconStyle,
    overrideModalStyle,
    overrideLeftSideElementStyle,
    overrideNavBarStyle,
    parentToggle,
    toastContainerId,
    setParentClose,
    scrollable,
  } = props;

  const isHumanSite = useState<boolean>(window.location.origin.includes("human"))[0]

  useEffect(() => {
  }, []);

  useEffect(() => {

  }, [leftSideElement])

  return (<OurModal disableClose={disableClose}
                    parentToggle={parentToggle}
                    setParentClose={setParentClose}
    >
      <div className="modal-container paper-base"
           data-is-human={isHumanSite}
           style={overrideModalStyle}>
        {disableClose && leftSideElement === undefined ? undefined : <>
          <div className="flex-row paper-modal-nav-bar"
               data-is-human={isHumanSite}
               style={overrideNavBarStyle}>
            <div className="paper-modal-nav-backdrop"
                 data-is-human={isHumanSite}/>
            <div className="left-text paper-modal-left-side-element" style={overrideLeftSideElementStyle}>
              {leftSideElement}
            </div>
            {disableClose ? undefined :
              <div className="paper-modal-close-icon" style={overrideCloseIconStyle}>
                <CloseTooltip title={"Close"} arrow={false} placement={"top"}>
                  <IconButton size="small"
                              onClick={setParentClose}>
                    <CloseRoundedIcon/>
                  </IconButton>
                </CloseTooltip>
              </div>}
          </div>
        </>
        }
        <div className="modal-base" style={scrollable ? {height: "100%", overflowY: "auto"} : {}}>
          {children}
        </div>
      </div>
      {toastContainerId ? <OurToastContainer containerId={toastContainerId}/> : undefined}
    </OurModal>
  )

}

export default OurPaperModal;