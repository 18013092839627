import React, {FunctionComponent, useEffect, useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import "./OurCollapse.scss";

export type OurCollapseProps = {
  parentIsCollapsed: boolean,
}

const OurCollapse: FunctionComponent<OurCollapseProps> = (ourCollapseProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const {children, parentIsCollapsed} = ourCollapseProps;

  useEffect(() => {

  }, []);

  useEffect(() => {
    setIsCollapsed(parentIsCollapsed);
  }, [parentIsCollapsed])

  return (
    <Collapse in={isCollapsed}>
      <div className="our-collapse-container">
        {children}
      </div>
    </Collapse>
  )
}

export default OurCollapse;