import React, {CSSProperties, forwardRef, FunctionComponent, useEffect} from "react";
import Linkify from "linkify-react";

type Body2Props = {
  breakWord?: boolean,
  className?: string,
  id?: string,
  linkify?: boolean,
  style?: CSSProperties,
  onClick?: () => void,
  noOverflow?: boolean
}

const Body2: FunctionComponent<Body2Props> = forwardRef((body2Props, ref) => {
  const {
    breakWord,
    className,
    id,
    linkify,
    style,
    children,
    onClick,
    noOverflow,
  } = body2Props;

  useEffect(() => {

  }, []);

  const clazzName = className ? className + " body2" : "body2";

  const noOverflowStyle = noOverflow ? {
    overflow: "hidden",
    textOverflow: "ellipsis",
  } : {};

  return (
    <p id={id ? id : undefined}
       className={clazzName + (breakWord ? " break-word" : "")}
       onClick={onClick}
       style={{...style, ...noOverflowStyle}}
      // @ts-ignore
       ref={ref}>
      {linkify ?
        <Linkify options={{className: clazzName + " link", target: "_blank", rel: "external nofollow noopener"}}>{children}</Linkify>
        :
        children}
    </p>
  )
});

export default Body2;