import React, {FunctionComponent, useEffect} from "react";
import {ToastContainer} from "react-toastify";
import {ToastContainerProps} from "react-toastify/dist/types";
import {useThemePreference} from "../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import 'react-toastify/dist/ReactToastify.css';

type OutToastContainerProps = Pick<ToastContainerProps, "containerId" | "limit">

const OurToastContainer: FunctionComponent<OutToastContainerProps> = (ourToastContainerProps) => {
  const {containerId, limit} = ourToastContainerProps;
  const {isDarkTheme} = useThemePreference();

  useEffect(() => {

  }, []);

  return <div style={{position: "absolute"}}>
    <ToastContainer containerId={containerId}
                    enableMultiContainer={true}
                    limit={limit ? limit : 1}
                    newestOnTop={true}
                    position="bottom-left"
                    style={{textAlign: "left"}}
                    toastStyle={{borderRadius: "10px"}}
                    theme={isDarkTheme() ? "dark" : "light"}/>
  </div>;
}

export default OurToastContainer;