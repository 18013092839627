import React, {createContext, FunctionComponent, useContext, useEffect, useState} from "react";
import OurPaperModal from "../../components/OurPaperModal/OurPaperModal";
import "./CookieSettingsContext.scss";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import {CookiesProvider, useCookies} from "react-cookie";
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import UlLink from "../../components/UlLink/UlLink";
import {initGA} from "../../utils/GAUtils";
import OurCollapse from "../../components/OurCollapse/OurCollapse";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {getElementById} from "../../utils/displayUtils";
import Body1 from "../../components/Typography/Body1";
import Body2 from "../../components/Typography/Body2";
import {optInMixPanel, optOutMixPanel} from "../../utils/MixPanelUtils";
import {useThemePreference} from "../ThemePreferenceContext/ThemePreferenceProvider";

const buttonStyles = {
  boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  background: "#e0e0e0",
  borderRadius: "10px",
  color: "black",
  margin: "0.5rem",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
}

const analyticsArrowId = "cookie-arrow-id";
const necessaryArrowId = "cookie-necessary-id"

export const consentCookieConfig = {
  // if you change please look in index.html for clarity logic
  cookieName: "CookieConsent",
  expires: 365
}

type cookieConsentVisibleState = "hidden" | "show" | "byCookieValue";

const ConsentCookiesModalOpenContext = createContext({
  openCookieModal: () => {
  }
});

const analyticsCookiesPrefixes = ["_ga", "_gat", "_gid", "1P_JAR", "mp_", "_clck", "_clsk", "CLID"];

const useCookiesSettings = () => useContext(ConsentCookiesModalOpenContext);

const ConsentCookiesProvider: FunctionComponent<{}> = ({children}) => {
  const [cookies, setCookies, removeCookies] = useCookies([consentCookieConfig.cookieName]);
  const [allCookies] = useCookies([]);
  const [cookieConsentVisibleState, setCookieConsentVisibleState] = useState<cookieConsentVisibleState>("byCookieValue");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [analyticsChecked, setAnalyticsChecked] = useState<boolean>(getCookieConsentValue() === "true");
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState<boolean>(false);
  const [isNecessaryOpen, setIsNecessaryOpen] = useState<boolean>(false);
  const {isDarkTheme} = useThemePreference();

  useEffect(() => {

  }, []);

  function handleOuterAcceptAll() {
    initGA();
    optInMixPanel();
  }

  function openCookieModal() {
    setIsOpen(true);
  }

  function closeCookieModal() {
    setIsOpen(false);
    setIsAnalyticsOpen(false);
    setIsNecessaryOpen(false);
  }

  function toggleAnalyticsCollapsed() {
    const analyticsArrowStyle = getElementById(analyticsArrowId).style;
    if (isAnalyticsOpen) {
      analyticsArrowStyle.transform = "rotate(0)";
    } else {
      analyticsArrowStyle.transform = "rotate(90deg)";
    }

    setIsAnalyticsOpen(!isAnalyticsOpen);
  }

  function toggleNecessaryCollapsed() {
    const necessaryArrowStyle = getElementById(necessaryArrowId).style;
    if (isNecessaryOpen) {
      necessaryArrowStyle.transform = "rotate(0)";
    } else {
      necessaryArrowStyle.transform = "rotate(90deg)";
    }

    setIsNecessaryOpen(!isNecessaryOpen);
  }

  function handleAnalyticsChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAnalyticsChecked(event.target.checked);
  }

  function handleModalAcceptAll() {
    setConsentCookieTo(true);
    setCookieConsentVisibleState("hidden");
    initGA();
    optInMixPanel();
    closeCookieModal();
  }

  function handleConfirmMyChoices() {
    if (analyticsChecked) {
      handleModalAcceptAll();
      return;
    }

    removeTrackingCookies();
    setConsentCookieTo(false);
    setCookieConsentVisibleState("hidden");
    window.location.reload();
  }

  function removeTrackingCookies() {
    optOutMixPanel();

    Object.keys(allCookies).forEach(cookieName => {
      if (analyticsCookiesPrefixes.some(prefix => cookieName.startsWith(prefix))) {
        removeCookies(cookieName, {path: '/'});
      }
    })
  }

  function setConsentCookieTo(newValue: boolean) {
    // we have to do it this way cause lib doesn't give us a way to trigger their accept/decline logic
    // sharing the cookie config as such doesn't make it as bad. The math is to convert the age from seconds to days
    setCookies(consentCookieConfig.cookieName, newValue, {maxAge: consentCookieConfig.expires * 60 * 60 * 24, path: "/", sameSite: "lax"});
  }

  return (<ConsentCookiesModalOpenContext.Provider value={{openCookieModal}}>
    <CookiesProvider>
      {children}
      <CookieConsent buttonClasses="cookie-buttons"
                     containerClasses="cookie-consent-container"
                     ButtonComponent={Button}
                     buttonStyle={buttonStyles}
                     cookieName={consentCookieConfig.cookieName}
                     declineButtonClasses="cookie-buttons"
                     declineButtonText={"Customize settings"}
                     declineButtonStyle={buttonStyles}
                     enableDeclineButton={true}
                     expires={consentCookieConfig.expires}
                     hideOnDecline={false}
                     onAccept={handleOuterAcceptAll}
                     onDecline={openCookieModal}
                     setDeclineCookie={false}
                     contentStyle={{
                       flex: "0 1 auto",
                       maxWidth: "40ch",
                     }}
                     style={{
                       backgroundColor: isDarkTheme() ? (window.location.origin.includes("human") ? "hsl(200, 68%, 15%)" : "#0d1117") : "white",
                       boxShadow: "0px -2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                       color: "var(--clr-grey)",
                       paddingLeft: "0.5rem",
                       paddingRight: "0.5rem",
                       alignItems: "center",
                       paddingTop: "0.5rem",
                       paddingBottom: "0.5rem",
                       fontSize: "14.4px",
                       width: "auto",
                       maxWidth: "385px",
                       marginLeft: "0.5rem",
                       marginRight: "0.5rem",
                       marginBottom: "1.5rem",
                       borderRadius: "10px",
                       zIndex: 1201,
                     }}
                     buttonText="Accept all cookies"
                     visible={cookieConsentVisibleState}>
        By clicking “Accept all cookies”, you agree Artistree can store cookies on your device and
        disclose information in accordance with our <UlLink link={"/cookie-policy"}
                                                            text={"Cookie Policy"}/>.
      </CookieConsent>
      <OurPaperModal parentToggle={isOpen}
                     setParentClose={closeCookieModal}
                     leftSideElement={<h5 className="h5point5 semi-bold">Cookie Settings</h5>}
      >
        <div className="cookie-settings-container">
          <Body2 className="cookie-settings-text">
            When you visit any of our websites, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you,
            your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it
            can
            give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the
            different
            category arrows to find out more and manage your preferences. Please note, blocking some types of cookies may impact your experience of the site and the
            services we are able to offer.
          </Body2>
          <div className="flex-row auto-in-between">
            <div className="cookie-settings-label">
              <Body1 className="bold">Strictly Necessary</Body1>
              <ArrowForwardIosRoundedIcon className="cookie-settings-arrow"
                                          id={necessaryArrowId}
                                          onClick={toggleNecessaryCollapsed}/>
            </div>
            <Switch defaultChecked={true}
                    disabled={true}
                    color={"primary"}
            />
          </div>
          <OurCollapse parentIsCollapsed={isNecessaryOpen}>
            <Body2 className="cookie-settings-text">
              These cookies are necessary for our website to function properly and cannot be switched off in our systems. They are usually only set in response
              to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms or where
              they’re essential to provide you with a service you have requested. You cannot opt-out of these cookies. You can set your browser to block or
              alert you about these cookies, but if you do, some parts of the site will not then work. These cookies do not store any personally identifiable
              information.
            </Body2>
          </OurCollapse>
          <div className="flex-row auto-in-between">
            <div className="cookie-settings-label">
              <Body1 className="bold">Analytics Cookies</Body1>
              <ArrowForwardIosRoundedIcon className="cookie-settings-arrow"
                                          id={analyticsArrowId}
                                          onClick={toggleAnalyticsCollapsed}/>
            </div>
            <Switch color={"primary"}
                    checked={analyticsChecked}
                    onChange={handleAnalyticsChange}
            />
          </div>
          <OurCollapse parentIsCollapsed={isAnalyticsOpen}>
            <Body2>
              These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which
              pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and
              therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.
            </Body2>
          </OurCollapse>

          <div className="cookie-settings-buttons-container">
            <Button color={"primary"}
                    variant={"contained"}

                    onClick={handleConfirmMyChoices}
            >
              Confirm my choices
            </Button>
            <Button variant={"contained"}
                    onClick={handleModalAcceptAll}
            >
              Accept all cookies
            </Button>
            <Button color={"primary"}
                    onClick={closeCookieModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </OurPaperModal>
    </CookiesProvider>
  </ConsentCookiesModalOpenContext.Provider>)
}

export function removePersonalCookie() {
  // const gaPersonalCookie = document.cookie.split("; ").find(str => str.startsWith("_ga_"));
  const cookies = document.cookie.split("; ");
  cookies.forEach(cookie => {
    if (analyticsCookiesPrefixes.some(prefix => cookie.startsWith(prefix))) {
      let gaPersonalCookieName = cookie.split("=")[0];
      document.cookie = gaPersonalCookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  })

}

export {ConsentCookiesProvider, useCookiesSettings};