import {alpha, createStyles, makeStyles, styled, withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import {TypographyOptions} from "@material-ui/core/styles/createTypography";

const body1 = 15.4;

export const MuiTypography: TypographyOptions = {
  htmlFontSize: body1,
  fontSize: body1,
  h1: {
    fontSize: 92.5,
    lineHeight: 1,
    letterSpacing: -1.5
  },
  h2: {
    fontSize: 57.8,
    lineHeight: 1.15,
    letterSpacing: -0.5
  },
  h3: {
    fontSize: 46.2,
    lineHeight: 1.25,
    letterSpacing: 0
  },
  h4: {
    fontSize: 32.7,
    lineHeight: 1.35,
    letterSpacing: 0.25
  },
  h5: {
    fontSize: 23.1,
    lineHeight: 1.45,
    letterSpacing: 0
  },
  h6: {
    fontSize: 19.2,
    lineHeight: 1.5,
    letterSpacing: 0.15
  },
  body1: {
    fontSize: body1,
    lineHeight: 1.5,
    letterSpacing: 0.5,
  },
  body2: {
    fontSize: 13.4,
    lineHeight: 1.5,
    letterSpacing: 0.25
  },
  caption: {
    fontSize: 12,
  },
  overline: {
    fontSize: 10
  },
  fontWeightBold: 600,
  fontFamily: [
    'Poppins',
    'system-ui',
    'sans-serif',
  ].join(','),
  button: {
    textTransform: "none",
  }
};

export const CallToActionButton = styled(Button)({
  paddingTop: "0.65rem",
  paddingBottom: "0.65rem",
  paddingLeft: "1.25rem",
  paddingRight: "1.25rem",
  fontSize: "1.11rem",
  fontWeight: 600,
});

export const CloseTooltipStyling = {
  tooltipPlacementTop: {
    margin: "6px 0",
  },
  tooltipPlacementBottom: {
    margin: "10px 0",
  },
  tooltipPlacementRight: {
    margin: "0 6px",
  },
  tooltipPlacementLeft: {
    margin: "0 6px",
  },
}

export const CloseTooltip = withStyles({
  ...CloseTooltipStyling,
})(Tooltip);

export const CloseBottomTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: "10px 0",
  },
})(Tooltip);

export function chipWithClr(backgroundClr: string, hoverClr: string): any {
  return withStyles({
    outlined: {
      backgroundColor: backgroundClr,
      color: "white",
      "&&:hover": {
        backgroundColor: hoverClr,
      }
    }
  })(Chip);
}

export function buttonWithClr(bgClr: string, textClr: string): any {
  return withStyles({
    root: {
      color: textClr,
      backgroundColor: bgClr,
      '&:hover': {
        borderColor: bgClr,
        backgroundColor: alpha(bgClr, 0.85),
      },
    },
  })(Button);
}

export function radioWithClr(passedInColor: string): any {
  return withStyles({
    root: {
      color: passedInColor,
      '&$checked': {
        color: passedInColor,
      },
    },
    checked: {},
  })(Radio);
}

export function switchWithClr(passedInColor: string): any {
  return withStyles({
    switchBase: {
      '&$checked': {
        color: passedInColor,
      },
      '&$checked + $track': {
        backgroundColor: passedInColor,
      },
    },
    checked: {},
    track: {
      backgroundColor: passedInColor
    },
  })(Switch);
}

export function textFieldWithClr(passedInColor: string): any {
  return withStyles({
    root: {
      '& label.Mui-focused': {
        color: passedInColor,
      },
      '& .MuiInput-underline:not(.Mui-error):after': {
        borderBottomColor: passedInColor,
      },
      '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //   borderColor: passedInColor,
        // },
        '&:hover fieldset': {
          borderColor: passedInColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: passedInColor,
        },
      },
    }
  })(TextField);
}

export function formControlWithClr(passedInColor: string): any {
  return withStyles({
    root: {
      '& label.Mui-focused': {
        color: passedInColor,
      },
      '& .MuiInput-underline:not(.Mui-error):after': {
        borderBottomColor: passedInColor,
      },
      '& .MuiInputLabel-outlined[data-shrink="false"]': {
        display: "none",
      },
      '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //   borderColor: passedInColor,
        // },
        '&:hover fieldset': {
          borderColor: passedInColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: passedInColor,
        },
      },
    }
  })(FormControl);
}

export function checkboxWithClr(passedInColor: string): any {
  return withStyles({
    root: {
      color: passedInColor,
      '&$checked': {
        color: passedInColor,
      },
    },
    checked: {
      color: passedInColor,
    },
  })(Checkbox);
}

export const useRadioStyles = makeStyles({
  smallRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
    padding: "4px",
  },
  mediumRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.35rem",
    },
    padding: "6px",
  },
  growLabel: {
    "& .MuiFormControlLabel-label": {
      flexGrow: 1,
    },
  }
});

export const useAccountIconStyles = makeStyles(() =>
  createStyles({
    button: {
      minWidth: "30px",
    },
    popper: {
      zIndex: 1101,
    }
  })
);