import Button from "@material-ui/core/Button";
import {createTheme, styled} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {DARK_THEME, ThemePreferences} from "../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import {MuiTypography} from "../../styles/MaterialUIStyling";

export const clrPrimaryGreen = "hsl(128, 52%, 47%)";
export const clrSecondaryGreen = "hsl(86,53%,51%)";
export const clrRed = "hsl(335, 82%, 54%)";
export const clrPurple = "hsl(279, 87%, 69%)"
export const clrYellow = 'hsl(45, 100%, 60%)';
export const clrOrange = "hsl(35, 93%, 58%)"
export const clrBlack = "hsl(345, 6%, 13%)";
export const clrPrimaryBlue = "hsl(198, 74%, 51%)"
export const clrSecondaryBlue = "hsl(220, 83%, 50%)"
export const clrPrimaryBlueLight = "hsl(179, 98%, 35%)"
export const clrSecondaryBlueLight = "hsl(185, 90%, 62%)";
export const clrGrey = "hsl(0, 0%, 42%)";
export const clrWhite = "hsl(0,0%,100%)";

// Dark theme colors
export const clrBlackSecondary = "#0d1117";
export const clrBlueGrey = "#161b22";
export const clrIceBlue = "#c9d1d9";
export const clrGreyBorder = "hsl(213,10%,18%)";

export const ourColorsList = ['#FFCB32', '#F7A430', '#39B54A', '#02B3B1', '#175EE9', '#C46CF5'];

export function getOurColorsList(opacity: number) {
  return [
    `hsl(335, 82%, 54%, ${opacity})`,
    `hsl(35, 93%, 58%, ${opacity})`,
    `hsl(128, 52%, 47%, ${opacity})`,
    `hsl(220, 83%, 50%, ${opacity})`,
    `hsl(198, 74%, 51%, ${opacity})`,
    `hsl(279, 87%, 69%, ${opacity})`
  ]
}

const body1 = 15.4;
const horizontalPaddingConstant = "0.5rem";

const hideNumberArrows = {
  '& input[type=number]': {
    '-moz-appearance': 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  }
}

const outlinedButtonBorder = "2.5px solid";

export const getArtistreeMaterialUIStyling = (themePreference: ThemePreferences, isDarkTheme: boolean) => {

  return createTheme({
    palette: {
      type: themePreference,
      background: {
        default: isDarkTheme ? clrBlackSecondary : "#fafafa",
        paper: isDarkTheme ? clrBlackSecondary : "#fff"
      },
      common: {
        black: clrBlack
      },
      primary: {
        main: clrPrimaryGreen,
        contrastText: clrWhite,
        dark: "hsl(128, 52%, 44%)",
        light: "hsl(128, 52%, 80%)"
      },
      secondary: {
        main: "hsl(86, 53%, 51%)",
        contrastText: clrWhite,
        dark: "hsl(86, 53%, 48%)"
      },
      error: {
        main: clrRed
      },
      warning: {
        main: clrYellow,
        contrastText: clrBlack,
        dark: "hsl(45, 100%, 57%)"
      },
      info: {
        main: clrPrimaryBlue,
        contrastText: clrWhite,
        dark: "hsl(198, 74%, 48%)"
      },
      success: {
        main: clrPrimaryGreen,
        contrastText: clrWhite,
        dark: "hsl(128, 52%, 44%)"
      },
      text: {
        primary: isDarkTheme ? clrIceBlue : clrBlack,
        secondary: isDarkTheme ? clrIceBlue : clrGrey
      },
    },
    shape: {
      borderRadius: 10
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: isDarkTheme ? clrBlueGrey : clrWhite
        }
      },
      MuiPaper: {
        rounded: {
          border: "1px solid " + (isDarkTheme ? clrGreyBorder : "lightgrey"),
          backgroundColor: isDarkTheme ? clrBlackSecondary : "#fff"
        }
      },
      MuiButton: {
        root: {
          fontSize: 13.4,
        },
        textSizeLarge: {
          fontSize: "13.4px",
          letterSpacing: "1.25px"
        },
        textSizeSmall: {
          fontSize: "13.4px",
          letterSpacing: "1.25px"
        },
        containedSizeSmall: {
          fontSize: 15
        },
        outlinedSecondary: {
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          }
        },
        outlinedPrimary: {
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          }
        },
        outlined: {
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          },
          color: isDarkTheme ? clrIceBlue : clrGrey
        },
        text: {
          color: isDarkTheme ? clrIceBlue : clrGrey
        }
      },
      MuiButtonBase: {
        root: {
          fontSize: 13.4,
          letterSpacing: 0.5,
        }
      },
      MuiOutlinedInput: {
        root: {
          ...hideNumberArrows,
        },
        input: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 10,
          paddingRight: 10,
        },
        notchedOutline: {
          // I'm glad you're seeing this ✨magic number✨, so based on the comment linked below, material ui figures out the notched outline width based on the font size.
          // https://github.com/mui/material-ui/issues/16833#issuecomment-1136374597
          // The exact number was based on trial and error. Out of all the possible things I can be doing right now and all the possible timelines I can be on, I'm here...
          fontSize: 14.70,
        }
      },
      MuiInputBase: {
        root: {
          fontSize: 13.4
        }
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 7,
          marginRight: 7,
        }
      },
      MuiInput: {
        root: {
          ...hideNumberArrows,
        }
      },
      MuiInputLabel: {
        outlined: {
          background: isDarkTheme ? "inherit" : "white",
        }
      },
      MuiBottomNavigationAction: {
        label: {
          fontSize: 11,
          minHeight: 32,
          maxWidth: 80,
          "&$selected": {
            fontSize: 11.5,
          }
        }
      },
      MuiTable: {
        root: {
          border: "1px solid lightgrey",
          borderRadius: "10px",
          borderCollapse: "separate"
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: 12,
          letterSpacing: 0.4,
          fontWeight: 400,
          color: isDarkTheme ? clrIceBlue : clrWhite,
          backgroundColor: isDarkTheme ? clrGreyBorder : "hsla(0, 0%, 38%, 0.9)",
        },
        arrow: {
          color: isDarkTheme ? clrGreyBorder : "hsla(0, 0%, 38%, 0.9)",
        }
      },
      MuiTableRow: {
        root: {
          "&:last-child > *": {
            borderBottom: "unset",
          }
        }
      },
      MuiTableCell: {
        root: {
          fontSize: body1,
          padding: horizontalPaddingConstant + " 0",
          borderRight: "1px solid lightgrey",
          paddingLeft: "0.5rem",
          "&:nth-child(1)": {
            fontWeight: 500,
            paddingRight: "0.25rem"
          },
          "&:last-child": {
            borderRight: "unset"
          }
        }
      },
      MuiChip: {
        outlined: {
          backgroundColor: isDarkTheme ? clrBlueGrey : "initial",
        },
        deleteIcon: {
          color: isDarkTheme ? "lightgray" : clrGrey
        }
      },
      MuiTab: {
        root: {
          marginLeft: "auto",
          marginRight: "auto",
        }
      },
      MuiSvgIcon: {
        colorAction: {
          color: isDarkTheme ? clrIceBlue : "grey",
        }
      },
    },
    typography: MuiTypography,
  });
}

export const StyledNegativeButton = styled(Button)({
  color: "white",
  backgroundColor: clrRed,
  "&:hover": {
    backgroundColor: "hsl(335, 82%, 49%)",
    borderColor: clrRed,
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: clrRed
    }
  }
});

export const StyledNegativeIconButton = styled(IconButton)({
  color: clrRed,
  "&:hover": {
    backgroundColor: "hsla(335, 82%, 54%, 0.05)",
  }
});

export const StyledNegativeOutlinedButton = styled(Button)(({theme}) => ({
  color: theme.palette.type === DARK_THEME ? clrIceBlue : clrGrey,
  borderColor: clrRed,
  "&:hover": {
    backgroundColor: theme.palette.type === DARK_THEME ? "hsla(335, 82%, 54%, 0.1)" : "hsla(335, 82%, 54%, 0.05)",
    borderColor: clrRed,
  }
}));

export const offBrandDarkGreenClr = "hsl(181, 35%, 27%)";
export const StyledOffBrandGreenButton = styled(Button)({
  color: "white",
  backgroundColor: offBrandDarkGreenClr,
  "&:hover": {
    color: "hsl(181, 35%, 24%)",
    backgroundColor: "hsla(345, 6%, 13%, 0.05)",
    borderColor: "hsl(181, 35%, 24%)",
    border: "2.5px solid",
    padding: "3.5px 13.5px",
  }
});

export const StyledOutlinedPrimaryGreenButton = styled(Button)(({theme}) => ({
  color: theme.palette.type === DARK_THEME ? clrIceBlue : clrGrey,
  borderColor: clrPrimaryGreen,
  "&:hover": {
    borderColor: clrPrimaryGreen,
  },
}));

export const StyledOutlinedBlueButton = styled(Button)(({theme}) => ({
  color: theme.palette.type === DARK_THEME ? clrIceBlue : clrGrey,
  borderColor: clrSecondaryBlue,
  "&:hover": {
    borderColor: clrSecondaryBlue,
  },
}));

export const StyledOutlinedGreyButton = styled(Button)(({theme}) => ({
  color: clrGrey,
  borderColor: clrGrey,
  "&:hover": {
    borderColor: clrGrey,
  },
}));

export const StyledBlueButton = styled(Button)({
  color: "white",
  backgroundColor: clrSecondaryBlue,
  "&:hover": {
    backgroundColor: "hsl(220, 83%, 47%)",
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: clrSecondaryBlue
    }
  }
});

