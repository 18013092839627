import React, {CSSProperties, FunctionComponent, useEffect} from "react";
import Linkify from "linkify-react";


type Body1Props = {
  breakWord?: boolean,
  className?: string
  id?: string,
  linkify?: boolean,
  title?: string,
  style?: CSSProperties,
  testId?: string,
  noOverflow?: boolean
}

const Body1: FunctionComponent<Body1Props> = (body1Props) => {
  const {
    breakWord,
    className,
    id,
    linkify,
    title,
    style,
    children,
    testId,
    noOverflow,
  } = body1Props;

  useEffect(() => {

  }, []);

  const clazzName = className ? className + " body1" : "body1";

  const noOverflowStyle = noOverflow ? {
    overflow: "hidden",
    textOverflow: "ellipsis",
  } : {};

  return (
    <p id={id} title={title} className={clazzName + (breakWord ? " break-word" : "")} style={{...style, ...noOverflowStyle}} data-testid={testId}>
      {linkify ?
        <Linkify options={{className: clazzName + " link", target: "_blank", rel: "external nofollow noopener"}}>{children}</Linkify>
        :
        children}
    </p>
  )
}

export default Body1;