import React, {CSSProperties, FunctionComponent, useEffect, useState} from "react"
import "./UtilsStyles.scss"
import {Link as RouterLink} from "react-router-dom";

export type UlLinkProps = {
  link: string,
  newTab?: boolean
  text?: string | any,
  style?: CSSProperties,
  reloadDocument?: boolean,
}

const UlLink: FunctionComponent<UlLinkProps> = (ulLinkProps) => {
  const {
    link,
    newTab,
    text,
    style,
    reloadDocument
  } = ulLinkProps;
  const displayText = text === undefined ? link : text

  const [fullUrl, setFullUrl] = useState<boolean>(false);

  useEffect(() => {

  }, []);

  useEffect(() => {
    setFullUrl(link.startsWith("http"))
  }, [link]);

  function getLinkElement() {
    if (fullUrl || newTab) {
      return <a className="UlLinks"
                href={link}
                rel={newTab ? "external nofollow noopener noreferrer" : ""}
                target={newTab ? "_blank" : ""}
                style={style}>
        {displayText}
      </a>
    }
    return <RouterLink className="UlLinks"
                       reloadDocument={reloadDocument !== undefined ? reloadDocument : true}
                       replace={false}
                       style={style}
                       to={link}>
      {displayText}
    </RouterLink>
  }

  return (<>
    {getLinkElement()
    }
  </>)

}

export default UlLink;